<template>
  <v-dialog
    v-model="isVisible"
    content-class="auth-error"
    max-width="300px"
    :hide-overlay="true"
    :persistent="true"
    :no-click-animation="true"
    :scrollable="true"
  >
    <v-card
      v-if="error"
      ref="draggableModal"
      max-width="300px"
      max-height="60%"
      @mousedown="dragStart"
      @mousemove="dragging"
    >
      <v-row>
        <v-col class="flex-grow-1 col-8 ml-6 my-3 pa-0">
          <v-card-title>
            <div class="darkblue--text subtitle-2 text-uppercase">
              <span>
                Error {{ code }}
              </span>
            </div>
          </v-card-title>
        </v-col>

        <v-spacer />

        <v-col class="flex-shrink-1 col-3 mt-3">
          <v-btn
            :ripple="false"
            icon
            small
            @click.stop="close()"
          >
            <v-icon
              color="brightblue"
              icon="close"
            >
              close
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-divider />

      <v-card-text class="pt-3 pb-6">
        <v-col>
          <v-row>
            <span class="font-weight-bold">
              {{ message }}
            </span>
          </v-row>

          <v-row>
            <p>
              {{ details }}
            </p>

            <p>
              {{ hint }}
            </p>
          </v-row>

          <v-row>
            <span>
              Please try again
              or
              <a
                class="font-weight-bold pl-1"
                href="mailto:tech@oao.freshdesk.com"
              >
                contact us
              </a>
              with the details below.
            </span>
          </v-row>

          <v-row class="mt-6 offwhite">
            <code
              class="offwhite pa-3 copy-field"
            >
              {{ JSON.stringify({ errorId }, undefined, 2) }}
            </code>
          </v-row>
        </v-col>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import draggable from 'src/utils/draggable'

const { mapState } = createNamespacedHelpers('layout')

export default {
  name: 'ErrorCard',
  data() {
    return {
      dragger: {},
    }
  },
  computed: {
    ...mapState({
      promptError: (state) => state.promptError,
    }),
    isVisible: {
      get() {
        return this.promptError
      },
      set(value) {
        this.$store.commit('layout/promptError', value)
      },
    },
    error() {
      return this.promptError?.error || false
    },
    code() {
      const { code } = this.error || {}
      return code || 500
    },
    details() {
      return this.promptError && this.promptError.details
    },
    errorId() {
      return this.promptError && this.promptError.errorId
    },
    hint() {
      return this.promptError && this.promptError.hint
    },
    layer() {
      const { layer } = this.error || {}
      return layer || 'app'
    },
    message() {
      const { message } = this.error || {}
      return message || 'Unknown Auth Error'
    },
  },
  created() {
    document.addEventListener('mouseup', this.finishDragging)
  },
  beforeDestroy() {
    document.removeEventListener('mouseup', this.finishDragging)
  },
  methods: {
    close() {
      this.isVisible = false
    },
    ...draggable,
  },
}
</script>

<style lang="scss" scoped>
@import "~src/styles/variables";

.v-card {
  position: fixed;
  left: calc(50% - 150px);
  top: 36px;
}

.copy-field {
  max-height: 150px;
  overflow: scroll;
}
</style>
